:global {
    .drop-drag-zone {
        position: relative;
        .ant-upload-drag {
            text-align: start;
            background: none;
            border: none;
            &::after {
                transform: 0.3s all ease-in;
            }
        }
        .ant-upload-drag .ant-upload {
            padding: 0;
            cursor: default;
        }
        .ant-upload-drag-hover {
            .ant-upload-drag-container * {
                pointer-events: none;
            }
        }
        .drag-layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background-color: #22222222;
            backdrop-filter: blur(10px);
            display: flex;
            flex-direction: column;
            align-items: center;
            // z-index: -1;
            opacity: 0;
            transition: all 0.2s ease-in-out;
            border-radius: 1rem;

            padding-top: 10vw;

            &.dragging {
                z-index: 1;
                opacity: 1;
            }
        }
    }
}