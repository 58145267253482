@import "../../../assets/styles/_variables.scss";

.auth {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  // background-image: url(../../../assets/images/bg-login.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: auto 100%;
}

.logo {
  margin-top: 2vw;
  max-height: 8vw;

  @media #{$mobile} {
    width: 60vw;
    margin-top: 0;
    max-height: 28vw;
  }
}

.bgLayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 40rem;
  height: 100%;
  background-color: #072a38;

  display: flex;
  align-items: center;
  justify-content: center;

  @media #{$tabletPortrait} {
    width: 40vw;
  }

  @media #{$mobile} {
    display: none;
  }

  object {
    width: 40vw;
    height: auto;
    margin-right: -14rem;
  }
}

:global {
  .box-locale {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;
  }
  .ensinger,
  .lst247,
  .brecht,
  .hertenberger,
  .kurios,
  .rich {
    position: relative;
    .wrapper {
      min-height: 100vh;
      background-color: var(--bs-primary);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      background-attachment: fixed;

      &::before {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--bs-primary);
        mix-blend-mode: multiply;
        backdrop-filter: blur(0px);
        z-index: 0;
        // opacity: 0.5;
      }
    }

    .page-wrapper {
      position: relative;
      background: #ffffffaa;
      backdrop-filter: blur(4px);
      padding: 1rem;
      @media #{$mobile} {
        padding: 3rem 0rem;
      }
    }

    .logo {
      margin-top: 2vw;
      max-height: 5vw;

      @media #{$mobile} {
        width: 60vw;
        margin-top: 0;
        max-height: 28vw;
      }
    }

    .hero-text {
      position: relative;
      color: #fff;
      padding: 5rem;

      > * {
        position: relative;
      }

      h1,
      h4,
      h5,
      h6 {
        color: #fff;
      }
      p {
        margin: 0;
      }

      @media #{$mobile} {
        padding: 2rem;
      }
    }

    .steps {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: 1.5rem;
      .step {
        display: flex;
        align-items: center;
        gap: 1rem;
        --step-size: 3.6rem;

        > div:nth-of-type(2) {
          width: calc(100% - var(--step-size));
        }

        .step-number {
          display: flex;
          align-items: center;
          justify-content: center;
          width: var(--step-size);
          height: var(--step-size);
          background-color: #fff;
          color: var(--bs-primary);
          font-size: 2rem;
          font-weight: 900;
          border-radius: 50%;

          @media #{$mobile} {
            font-size: 2rem;
          }
        }

      }
    }
  }

  .ensinger {
    .logo {
      max-height: 3.8vw;
      @media #{$mobile} {
        max-height: unset;
      }
    }
    .wrapper {
      background-image: url(../../../assets/images/Machined-Parts-milling-knee-implants-Cham--01237-20231129-130328.jpg);

      &::before {
        content: "";
        background-color: var(--bs-primary);
      }
    }
    .page-wrapper {
      background: #ffffff;
    }
    .steps .step {
      align-items: start;
    }
    .video-intro {
      width: 100%;
    }

    .box-locale {
      display: flex;
      gap: 1rem;
    }
  }

  .lst247 {
    .video-bg {
      position: fixed;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      pointer-events: none;

      @media #{$mobile} {
        width: auto;
        max-height: 100vh;
      }
    }
    .wrapper {
      // background-image: url(../../../assets/images/Machined-Parts-milling-knee-implants-Cham--01237-20231129-130328.jpg);
      &::before {
        content: none;
      }
    }

    .page-wrapper {
      background-color: transparent;
      backdrop-filter: none;
      padding: 5rem 5rem 5rem 0;
    }
    .box-form {
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(5px);
      border-radius: 0.8rem;
      padding: 2.4rem 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    .logo {
      margin-top: 0;
      max-height: 6vw;
      @media #{$mobile} {
        max-height: unset;
      }
    }
  }

  .brecht {
    .wrapper {
      background-image: url(../../../assets/images/Fassade-20140313-181902.JPG);

      &::before {
        content: "";
        background-color: var(--bs-primary);
        display: none;
      }
    }
    .steps .step {
      align-items: start;
    }
    .video-intro {
      width: 100%;
    }
  }

  .hertenberger {
    .wrapper {
      background-image: url(../../../assets/images/IMG_5955-Verbessert-20220404-053846.jpg);

      &::before {
        content: "";
        background-color: var(--bs-primary);
        opacity: 0.5;
      }
    }
    .steps .step {
      align-items: start;
    }
    .video-intro {
      width: 100%;
    }
  }

  .kurios {
    .wrapper {
      background-image: url(../../../assets/images/shop.Kurios.pro-Login_Background_Frästeile_online_kalkulieren.jpg);

      &::before {
        content: "";
        background-color: var(--bs-primary);
        display: none;
      }
    }
    .steps .step {
      align-items: start;
    }
    .video-intro {
      width: 100%;
    }

    .hero-text {
      position: relative;
      color: #1f1f1f;
      padding: 4rem;

      > * {
        position: relative;
      }

      h1,
      h4,
      h5,
      h6 {
        color: #1f1f1f;
      }
      p {
        margin: 0;
      }

      @media #{$mobile} {
        padding: 2rem;
      }
    }
  }

  .rich {
    .wrapper {
      background-image: url(../../../assets/images/Hero-Rich-p-2000.jpg);
      background-position: bottom;

      &::before {
        content: "";
        background-color: var(--bs-primary);
        display: none;
      }
    }
    .steps .step {
      align-items: start;
      .step-number {
        background: #4C4C4A;
        color: #fff;
      }
    }
    .video-intro {
      width: 100%;
    }

    .hero-text {
      position: relative;
      color: #1f1f1f;
      padding: 4rem;

      > * {
        position: relative;
      }

      h1,
      h4,
      h5,
      h6 {
        color: #1f1f1f;
        text-shadow: 3px 3px 20px #fff,
          -2px 1px 30px #fff;
      }
      p {
        margin: 0;
        text-shadow: 3px 3px 20px #fff,
          -2px 1px 30px #fff;
      }

      @media #{$mobile} {
        padding: 2rem;
      }
    }
  }
}
